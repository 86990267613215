@import url("https://use.typekit.net/bpu1lfp.css");
/* https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_200/mto5squ8abpmd02zymev */
$red: #8c3817;
$black: #222;
$borderWidth: 4px;
$paddingY: 0.35em;

$sigFont: futura-pt, sans-serif;
$decoFont: kewl-script, sans-serif;
@import 'topo';

.site-content.californiaeatery {
  @include topobackground;
}

.californiaeatery {
  font-family: $sigFont;
  img.nav-bar-logo {
    display: block;
    padding-left: 0.3em;
  }
  .cart.minimized {
    @include topobackground;
    padding-bottom: 0.3em;
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
  }
  .landing-page-wrapper {
    h2, h3 {
      font-family: $sigFont;
      text-align: center;
      padding: 1em 0.2em;
      font-size: 1.3em;
      color: #ffffff;
    }
    background: #1d252c;
  }

  .landing-page, .top-banner {
    font-family: $decoFont;
    padding: 1em 0;
    margin: 0;
    display: block;
    min-height: 250px;
    /* max-height: 50vh; */
    // background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1540691096/PablitosKitchen_Hero-1_FullSize_blog_960x540_sc2pmh.jpg);
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_1200/swwm08laybc0craa1nsq);
    background-size: cover;
    background-position: center;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hero-logo {
    margin: 0 auto;
    max-width: 350px;
    img {
      width: 100%;
    }
  }
  .landing-page .text {
    color: white;
    text-align: center;
    font-weight: 900;
    line-height: 1.8em;
    border-radius: 0.2em;
    // background: rgba(0,0,0,0.5);
    padding: 0.5em 0.8em;
    text-shadow: 2px 2px 4px black;
    .highlight {
      // background: ;
    }

  }
   .ordering-header .text {
     border-radius: 0.3em;
     // background: rgba(0,0,0,0.5);
     padding: 0.5em 0.8em;
     text-shadow: 2px 2px 4px black;
     background: rgba(0,0,0,0.5);
     padding: 0.3em 0.5em;
   }

  a.checkout-button.button {
    font-family: $sigFont;
    background: $red;
    color: white;
    font-weight: 600;
    box-shadow: none;
    border: 2px solid black;

  }
  a.order-online-link {
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    font-size: 0.75em;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: white;
    margin: 0.5em 0;
    padding: 0.4em 0.6em;
    line-height: 1em;

    border-radius: 0.2em;
    background: #17bdff;
    position: relative;
    box-shadow: 1px 1px #114155, 3px 3px #114155, 5px 5px #114155, 7px 7px #114155;
    transition: all 0.12s ease 0s;
    &:hover {
      transform: translate(5px, 5px);
      box-shadow: none
    }
    &.disabled:hover {
      transform: none;
      box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
    }
    &.disabled {
      background: gray;
      box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
    }
  }

  /* .category__header {
  font-family: cursive;

} */
.category-name .text {
  font-family: $decoFont;
  font-weight: 600;
  font-size: 1.5em;
  background: none;
  color: black;
  // border: 2.5px solid black;
  // padding: 0.5em 0.;
  border-radius: 0;
}

.top-banner {
  // height: 30vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}

.pages-nav {
  font-family: $decoFont;
  background: none;
  font-weight: 600;
  // border-top:    1px solid black;
  // border-bottom: 1px solid black;
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    position: relative;
    top: 0.35em;
  }
  @media screen and (max-width: 480px) {
    font-size: 4vw;
  }
}

a.page-link {
  text-decoration: none;
  color: black;
  display: block;
  padding: $paddingY 0.3em;
  padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
  // box-sizing: border-box;
  // border-bottom: $borderWidth solid white;
  &:hover, &.active {
    color: $red;
    // border-bottom: $borderWidth solid black;
  }
}

.ordering-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_900/f9kkml4ik97s8tjs8cid);
  background-size: cover;
  background-position: center center;
  text-align: center;
  // font-family: "Chewy";
  color: white;
  height: 250px;
}

.full-menu-link-wrapper {
  text-align: center;
  a {
    // background: ;
    // color: white;
  }
  margin: 1em 0 2em 0;
}


}
