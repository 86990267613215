.form-wrapper {
  font-family: Helvetica;
  color: black;
  h2 {
    color: black !important;
  }
  form {
    background: white;
    border-radius: 0.5em;
    padding: 1em;
    color: black;
    border: 2px solid black;
    button {
      background: #0a4e61;
      color: white;
      width: 5em;
    }
  }
}
